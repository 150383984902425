import React from "react";
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import ProductHero from "components/product-hero";
import SlideContent from "components/slide-content";
import productData from "components/product-data";
import Slides from "components/product-data/slides";

import { TypeB } from "components/callouts";

import imgDexmedetomidine from "images/products/img-dexmedetomidine-hcl.png"
import bivalirudinBoxImg from "images/home/bivalirudin-box-and-vial.png";
import ephedrineBoxImg from "images/home/ephedrine-sulfate-injection-bottle.svg";
import prevduoBoxImg from "images/home/prevduo-box-and-syringe.png";
import vasostrictBoxImg from "images/home/vasostrict-bottles.svg";

import './products.scss';

const Reference = (
    <>
        <p>
            <strong>References: 1.</strong> Dexmedetomidine Hydrochloride Injection. Prescribing Information. Par Pharmaceutical, Inc.  <strong>2.</strong>  The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed November 8, 2023. <strong>3.</strong> Data on file. DOF-DX-01. Par Pharmaceutical, Inc.; July 27, 2023. <strong>4.</strong> Data on file. DOF-EH-01. Par Pharmaceutical, Inc.; July 31, 2023. <br className="show-desktop"/><strong>5.</strong> Data on file. DOF-PRVD-01. Par Pharmaceutical, Inc.; July 20, 2023. <strong>6.</strong> Data on file. DOF-VS-02. Par Pharmaceutical, Inc.; September 21, 2023. <strong>7.</strong> Bivalirudin Injection. Prescribing Information. Par Pharmaceutical, inc. <strong>8.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023.
        </p>
    </>
)

function DexmedetomidineHCIPage({ pageContext }) {
    return (
        <Layout
            product="dex"
            pageClass="products page-dexmedetomidine"
            reference={Reference}
            breadcrumbData={pageContext.breadcrumb}
        >
            <Seo pageTitle="Dexmedetomidine HCl Injection" pageDescription="Dexmedetomidine HCl ready-to-use vials" />

            <section className="first">
                <div className="centered-content">
                    <ProductHero
                        productTitle="Dexmedetomidine HCl <br class='show-desktop' /> Injection"
                        productSubTitle="Available in ready-to-use <br class='show-desktop' /> (RTU) bottles"
                        productUnitsOne="200 mcg/50 mL"
                        productUnitsTwo="400 mcg/100 mL"
                        productImg={imgDexmedetomidine}
                        productImgAlt="Dexmedetomidine HCI injection ready-to-use bottles"
                        className="prd-dexmedetomidine"
                    ></ProductHero>
                </div>
            </section>

            <section className="gray-bg">
                <div className="centered-content">
                    <Row align="start" justify="between">
                        <Col md={5.75} lg={4.5} xs={12}>
                            <SlideContent
                                subTitle="The benefits of RTU in the following <br class='show-desktop' /> concentrations<sup>1</sup>"
                                details="Dexmedetomidine HCl Injection RTU bottles do not require compounding, diluting, mixing, or transferring and provide extended dating compared with compounded products.<sup>1-3</sup>*
                                Dexmedetomidine HCl Injection RTU bottles are preservative-free and compatible with most automated dispensing machines." />
                        </Col>
                        <Col md={6} xs={12}>
                            <Slides data={productData.dexmedetomidine} />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="links-row">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>
                            <p><a href="/pdfs/PSP-DX-05010_Dexmedetomidine_RTU_Sell_Sheet_R2.pdf" target="_blank" rel="noopener noreferrer" className="pdf-link">Dexmedetomidine Information Document</a></p>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="cta-row">
                <div className="centered-content">
                    <div className="block-title">
                        <h3>Explore TruDelivery<sup>&reg;</sup> RTU portfolio</h3>
                    </div>

                    <Row justify="center">
                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Bivalirudin Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={bivalirudinBoxImg}
                                ctaImageAlt='Bivalirudin injection ready-to-use bottles'
                                pdfLink='https://www.parpharm.com/pdfs/catalog/sterile/v2_Bivalirudin_Injection_PI_04-2023.pdf'
                                buttonText="View Product"
                                buttonURL="/products/bivalirudin-injection"
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Ephedrine Sulfate<br class='show-desktop' /> Injection"
                                ctaSubTitle="Premixed vials"
                                ctaImage={ephedrineBoxImg}
                                ctaImageAlt='Ephedrine sulfate injection premixed vials'
                                pdfLink='https://www.endodocuments.com/documents/Ephedrine/Ephedrine_5_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/ephedrine-sulfate-injection"
                                captionClass='img-caption__right img-caption__rightAlt'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="PREVDUO<sup>&reg;</sup> (neostigmine  methylsulfate and  glycopyrrolate) Injection"
                                ctaSubTitle="Prefilled syringes"
                                ctaImage={prevduoBoxImg}
                                ctaImageAlt='Prevduo carton and prefilled syringe'
                                pdfLink='https://www.parpharm.com/pdfs/catalog/sterile/PREVDUO_PI_02-2023.pdf'
                                buttonText="View Product"
                                buttonURL="/products/prevduo-injection"
                                captionClass='img-caption__right'
                                className='pb-lg-0'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Vasostrict<sup>&reg;</sup><br />(Vasopressin Injection, USP)"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={vasostrictBoxImg}
                                ctaImageAlt='Vasostrict ready-to-use bottles'
                                pdfLink='https://www.endodocuments.com/documents/Vasostrict/Vasostrict_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/vasostrict-injection"
                                captionClass='img-caption__right'
                                className='pb-lg-0'
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="block-footnotes">
                <div className="centered-content">
                    <div className="footnotes">
                        <div className="footnotes-wrap">
                            <span className='symbol'>*</span>
                            <p className="footnotes-symbols">For Category 3 controlled sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>2</sup> versus shelf life of 2 years at CRT for Ephedrine, PREVDUO<sup>®</sup>, and Dexmedetomidine<sup>3-5</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®6</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>7,8</sup></p>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}

export default DexmedetomidineHCIPage;